import * as React from "react";
import Button from "../../atoms/Button/Button";
import * as Styled from "./TextBlock.styled";

export interface TextBlockProps {
    subtitle?: string;
    title: string
    button?: {
        label: string,
        onClick: () => void
    }
}

const TextBlock: React.FC<TextBlockProps> = ({subtitle,title, button, children}) => {
    return (
        <Styled.TextBlock>
            {subtitle && <p>{subtitle}</p>}
            <h2>{title}</h2>
            <h5>{children}</h5>
            {button && <Button onClick={button.onClick}>{button.label}</Button>}
        </Styled.TextBlock>
    )
}

export default TextBlock;