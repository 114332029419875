import * as React from "react";
import Section, {SectionProps} from "../Section/Section";
import * as Styled from "./SectionItems.styled";

const SectionItems: React.FC<SectionProps> = ({background, children, id}) => {
    return (
        <Section background={background} size={"small"} id={id}>
            <Styled.SectionItems>
                {children}
            </Styled.SectionItems>
        </Section>
    )
}

export default SectionItems;