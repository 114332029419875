import * as React from "react";
import * as Styled from "./Button.styled";
import {HTMLProps} from "react";
import Loader from "../Loader/Loader";

interface ButtonProps {
    loading?: boolean
}

const Button: React.FC<ButtonProps & HTMLProps<HTMLButtonElement>> = ({children, loading, ...props}) => {
    return (
        <Styled.Button
            disabled={props.disabled}
            onClick={props.onClick}
            loading={loading}
        >
            <Loader />
            <span>{children}</span>
        </Styled.Button>
    );
};

export default Button;