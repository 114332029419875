import * as React from "react";
import * as Styled from "./Card.styled";

interface CardProps {
    small?: boolean
}

const Card: React.FC<CardProps> = ({small, children}) => {
    return (
        <Styled.Card small={small}>
            {children}
        </Styled.Card>
    )
}

export default Card;