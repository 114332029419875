import * as React from "react";
import bannerImg from "../../../images/banner.png";
import * as Styled from "./Banner.styled";



const Banner: React.FC<{id: string}> = ({children, id}) => {
    return (
        <Styled.Banner imgSrc={bannerImg} id={id}>
            {children}
        </Styled.Banner>
    )
}

export default Banner;