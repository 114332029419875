import * as React from "react";
import * as Styled from "./SectiomImage.styled";
import TextBlock, {TextBlockProps} from "../../molecules/TextBlock/TextBlock";
import Quotation, {QuotationProps} from "../../molecules/Quotation/Quotation";

interface SectionImageProps extends TextBlockProps, QuotationProps {
    imageSrc: string,
    imagePosition?: "left" | "right",
    quotationContent: string | JSX.Element
}

const SectionImage: React.FC<SectionImageProps> =
    ({
        imagePosition = "left",
        imageSrc,
        author,
        children,
        quotationContent,
        ...props
    }) => {
        return (
            <Styled.SectionImage imagePosition={imagePosition}>
                <Styled.SectionImageWrapper imagePosition={imagePosition}>
                    <img src={imageSrc} />
                    <Quotation author={author}>{quotationContent}</Quotation>
                </Styled.SectionImageWrapper>
                <Styled.SectionImageContent>
                    <TextBlock {...props}>{children}</TextBlock>
                </Styled.SectionImageContent>
            </Styled.SectionImage>
        )
    }

export default SectionImage;