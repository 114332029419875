import styled from "styled-components";

export const QuotationMark = styled.h1`
  color: ${({theme}) => theme.colors.greenLight};
  line-height: 35px;
  position: relative;
  top: 12px;
`

export const QuotationAuthor = styled.p`
  font-size: 16px;
  line-height: 27px;
  margin-top: 24px;
`
