import * as Styled from "./CircleBlock.styled";
import * as React from "react";

export interface CircleProps {
    label: string;
    value: string | JSX.Element;
}

const CircleBlock: React.FC<CircleProps> = ({label, value}) => {
    return (
        <Styled.CircleBlock>
            <Styled.CircleBlockValue>
                {value}
            </Styled.CircleBlockValue>
            <Styled.CircleBlockLabel>{label}</Styled.CircleBlockLabel>
        </Styled.CircleBlock>
    )
}


export default CircleBlock;