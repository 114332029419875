import styled from "styled-components";

export const CircleBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media (max-width: 992px) {
    margin-bottom: 64px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const CircleBlockValue = styled.div`
  height: 128px;
  width:128px;
  border-radius: 100%;
  font-weight: 700;
  font-size: 24px;
  color: ${({theme}) => theme.colors.greenLight};
  background: white;
  filter: drop-shadow(0px 4px 40px rgba(19, 71, 38, 0.12));
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CircleBlockLabel = styled.h5`
  margin-top: 24px;
`