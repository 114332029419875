import styled from "styled-components";

export interface CardStyledProps {
    small?: boolean
}

export const Card = styled.div<CardStyledProps>`
  padding: ${(props) => props.small ? "16px": "32px"};
  border-radius: 24px;
  box-shadow: 0px 4px 40px rgba(19, 71, 38, 0.12);
  background: white;
  max-width: 430px;
  color: ${({theme}) => theme.colors.green};
`