import styled from "styled-components";
import {Button} from "../../atoms/Button/Button.styled";

export const TextBlock = styled.div`
  color: ${({theme}) => theme.colors.green};
  
  p {
    color: ${({theme}) => theme.colors.greenLight};
    text-transform: uppercase;
    font-weight: 300;
    font-size: 16px;
    line-height: 27px;
  }
  h5 {
    margin-top: 8px;
  }
  
  ${Button} {
    margin-top: 48px;
  }
`