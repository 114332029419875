import * as React from "react";
import Card from "../../atoms/Card/Card";
import * as Styled from "./ProductCard.styled";

export interface ProductCardProps {
    imageSrc: string,
    label: string,
    description?: string
}

const ProductCard: React.FC<ProductCardProps> = ({imageSrc, label, description = " "}) => {
    return (
        <Styled.ProductWrapper>
            <Card small>
                <Styled.ProductCardLink to={"#"}>
                    <img src={imageSrc} alt={label}/>
                    <h5>{label}</h5>
                    <Styled.ProductCardLinkHover>
                        <h4>{label}</h4>
                        <h6>{description}</h6>
                    </Styled.ProductCardLinkHover>
                </Styled.ProductCardLink>
            </Card>
        </Styled.ProductWrapper>

    )
}

export default ProductCard;