import * as React from "react";
import * as Styled from "./Quotation.styled";
import Card from "../../atoms/Card/Card";

export interface QuotationProps {
    author: string;
}

const Quotation: React.FC<QuotationProps> = ({author, children}) => {
    return (
        <Card>
            <Styled.QuotationMark>“</Styled.QuotationMark>
            <h4>{children}</h4>
            <Styled.QuotationAuthor>{author}</Styled.QuotationAuthor>
        </Card>
    )
}

export default Quotation;