import styled from "styled-components";
import {Loader} from "../Loader/Loader.styled";

export const Button = styled.button<{loading?: boolean}>`
    font-size: 14px;
    font-weight: 700;
    background: ${props => props.theme.colors.greenLight};
    color: white;
    border: none;
    padding: 15px 32px;
    text-transform: uppercase;
    border-radius: 24px;
    position: relative;
  
    
    span {
      visibility: ${props => props.loading ? "hidden" : "visible" }
    }
    &:hover {
      cursor: pointer;
    };
  
    &:disabled {
      opacity: 0.5;
    }
  
  ${Loader} {
    display: ${props => props.loading ? "block" : "none"};
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    
    &:after {
      position: relative;
      left: -10px;
      top: -5px;
    }
  }
`;

