import styled from "styled-components";
import {Card} from "../../atoms/Card/Card.styled";

interface SectionImageProps {
    imagePosition: "left" | "right"
}

export const SectionImage = styled.div<SectionImageProps>`
  padding: 128px 0;
  display: flex;
  flex-direction: ${(props) => props.imagePosition === "right" ? "row-reverse" : "row"};
  
  @media (max-width: 992px) {
    flex-direction: column;
  }
`

export const SectionImageWrapper = styled.div<SectionImageProps>`
  position: relative;
  img {
    width: 50vw;
    height: auto;
    border-radius: ${props => props.imagePosition === "left" ? "0 24px 24px 0" : "24px 0 0 24px"}
  }
  
  ${Card} {
    position: absolute;
    bottom: -20px;
    right: ${props => props.imagePosition === "left" ? "-20px" : "auto"};
    left: ${props => props.imagePosition === "right" ? "-20px" : "auto"};
  }

  @media (max-width: 992px) {
    img {
      width: 100%;
      border-radius: 0;
    }
    ${Card} {
      position: relative;
      min-width: calc(100% - 32px);
      left: 0;
      right: 0;
      margin: 0 16px;
      margin: 0 auto;
      top: -50px;
    }
  }
`

export const SectionImageContent = styled.div`
  display: flex;
  align-items: center;
  padding: 0 calc((100vw - 1240px) / 2);

  @media (max-width: 1200px) {
    padding: 0 calc((100vw - 960px) / 2);
  }

  @media (max-width: 992px) {
    padding: 0;
    max-width: 720px;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
`