import styled from "styled-components";
import {SectionWrapper} from "../../templates/Section/Section.styled";
import {TextBlock} from "../TextBlock/TextBlock.styled";

interface BannerStyledProps {
    imgSrc: string
}

export const Banner = styled.div<BannerStyledProps>`
  background: url(${(props) => props.imgSrc});
  width: 100%;
  height: 800px;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  
  ${SectionWrapper} {
    width: 100%;
  };
  
  ${TextBlock} {
    color: white;
    max-width: 570px;
  }
  
  @media (max-width: 768px) {
    ${TextBlock} {
      max-width: 100%;
    }
  }
`