import styled from "styled-components";
import {Link} from "gatsby";
import {Card} from "../../atoms/Card/Card.styled";

export const ProductWrapper = styled.div`
  
  ${Card} {
    position: relative;
    @media (max-width: 1400px)  {
      max-width: 250px;
      
      img {
        width: 100%;
        height: auto;
      }
    }
    @media (max-width: 992px) {
      max-width: 330px;
    }
  }
  
  @media (max-width: 992px) {
    margin-bottom: 64px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
  
`;

export const ProductCard = styled.img`
  display: block;
  margin: 0 auto;
`
export const ProductCardLinkHover = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius:24px;
  background: #555C58;
  padding: 24px;
  color: white;
  opacity: 0;
  transition: opacity 0.2s;
  
  h4 {
    margin-bottom: 8px;
  }
`

export const ProductCardLink = styled(Link)`
  color: currentColor;
  text-decoration: none;
  
  
  img {
    display: block;
    margin: 0 auto;
  }
  
  h5 {
    text-align: center;
  }
  
  &:hover {
    ${ProductCardLinkHover} {
      opacity: 1;
    }
  }
`

