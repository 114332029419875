import * as React from "react"
import Layout from "../components/templates/Layout/Layout";
import CircleBlock, {CircleProps} from "../components/atoms/CircleBlock/CircleBlock";
import Section from "../components/templates/Section/Section";
import ProductCard, {ProductCardProps} from "../components/molecules/ProductCard/ProductCard";
import TextBlock from "../components/molecules/TextBlock/TextBlock";
import Banner from "../components/molecules/Banner/Banner";
import SectionImage from "../components/templates/SectionImage/SectionImage";
import sectionImage1 from "../images/section-image-1.png";
import sectionImage2 from "../images/section-image-2.png";
import theme from "../styles/theme";
import additionsImage from "../images/additions.png";
import spicesImage from "../images/spices.png";
import mixturesImage from "../images/mixtures.png";
import pickleImage from "../images/pickles.png";
import SectionItems from "../components/templates/SectionItems/SectionItems";
import Contact from "../components/molecules/Contact/Contact";
import BioIcon from "../icons/bio.inline.svg";
import NatureIcon from "../icons/nature.inline.svg";
import AppleIcon from "../icons/apple.inline.svg";
import LeavesIcon from "../icons/leaves.inline.svg";
import {scrollTo} from "../utils/utils";

const products: ProductCardProps[] = [
    {
        label: "Zapach",
        imageSrc: spicesImage,
        description: ""
    },
    {
        label: "Smak",
        imageSrc: mixturesImage,
        description: ""
    },
    {
        label: "Konsystencja",
        imageSrc: pickleImage,
        description: ""
    },
    {
        label: "Funkcja",
        imageSrc: additionsImage,
        description: ""
    }
];

const metrics: CircleProps[] = [
    {
        label: "Zadowolenia",
        value: "100%"
    },
    {
        label: "Jakości",
        value: "100%"
    },
    {
        label: "Atrakcyjności",
        value: "100%"
    },
    {
        label: "Funkcjonalności",
        value: "100%"
    }
];

const features: CircleProps[] = [
    {
        label: "Produkty Ekologiczne",
        value: <BioIcon />
    },
    {
        label: "W zgodzie z naturą",
        value: <NatureIcon />
    },
    {
        label: "Stawiamy na jakość",
        value: <LeavesIcon />
    },
    {
        label: "Z myslą o Tobie",
        value: <AppleIcon />
    }
]

const IndexPage: React.FC = () => (
    <Layout title={"Home"}>
        <Banner id={"ourstory"}>
            <Section>
                <TextBlock
                    title={"Dostarczamy jakość"}
                    subtitle={"Jakość i profesjonalizm"}
                    button={{
                        label: "Zobacz wiecej",
                        onClick: () => scrollTo("#features")
                    }}
                >
                    Optymalizacja procesów produkcji żywności poprzez podnoszenie jakości, standaryzację i dywersyfikację dostaw.
                </TextBlock>
            </Section>
        </Banner>
        <SectionItems background={theme.colors.greyLight} id={"features"}>
            {features.map((feature, index) => <CircleBlock key={`${feature.label}-${index}`} {...feature} />)}
        </SectionItems>
        <SectionImage
            imageSrc={sectionImage1}
            title={"Naturalność"}
            subtitle={"Jakość i profesjonalizm"}
            button={{
                label: "Skontaktuj się z nami",
                onClick: () => scrollTo("#contact")
            }}
            author={""}
            quotationContent={"Produkty i usługi oferowane przez NPA Fumum to stabilność, jakość i sprawdzeni kontrahenci!"}
        >
            Rozwiązania oferowane przez NPA Fumum to dodanie szczypty jakości, standaryzacji i bezpieczeństwa do każdego produktu.
        </SectionImage>
        <SectionItems background={theme.colors.greyLight} id={"products"}>
            {products.map((product, index) => <ProductCard key={`${product.label}-${index}`} {...product} />)}
        </SectionItems>
        <SectionImage
            imagePosition={"right"}
            imageSrc={sectionImage2}
            title={"Nasza Historia"}
            subtitle={"Jakość i profesjonalizm"}
            button={{
                label: "Skontaktuj się z nami",
                onClick: () => scrollTo("#contact")
            }}
            author={""}
            quotationContent={"NPA Fumum rozumie jak ważne jest używanie wysokiej jakości produktów naturalnych i dodatków funkcjonalnych zwiększających jakość i bezpieczeństwo produktów."}
        >
             Nasza historia, to historia przyjaźni produktu z rynkiem, a wynika ona z wysokiej jakości obsługi firm produkcyjnych i długoletniego doświadczenia kluczowych osób w branży produkcji żywności oraz obsługi klienta.
        </SectionImage>
        <SectionItems background={theme.colors.greyLightest}>
            {metrics.map((metric, index) => <CircleBlock key={`${metric.label}-${index}`} {...metric} />)}
        </SectionItems>

        <Section background={theme.colors.greyLightest} size={"medium"} id={"contact"}>
           
        </Section>

    </Layout>
)

export default IndexPage
