import styled from "styled-components";

export const SectionItems = styled.div`
  display: flex;
  justify-content: space-between;
  
  
  @media (max-width: 992px) {
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    
  }
`